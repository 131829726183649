import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};

export const loadCities = (input) => {
  return fetch(`https://api-adresse.data.gouv.fr/search/?q=${input}&type=municipality&limit=15&autocomplete=1`)
    .then(response => response.json())
    .then(data => {
      return data?.features?.map(city => ({ value: `${city?.properties?.postcode ?? ''} | ` + `${city?.properties?.name ?? ''}`})) || [];
    })
    .catch(e => {
      console.log('Error: ', e);
      return [];
    });
};