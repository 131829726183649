import React, { Component } from 'react';
import { number, object, shape, string } from 'prop-types';
import { circlePolyline } from '../../util/maps';
import css from './Map.module.css';
import CYCLE from './img/cy.svg';
import CAR from './img/cr.svg';
import PT from './img/pt.svg';
import WALK from './img/wll.svg';
import DISTANCE from './img/route.svg';
import DURATION from './img/time.svg';
import Modal from '../Modal/Modal';
import { FormattedMessage,injectIntl } from 'react-intl';

/**
 * DynamicGoogleMap uses Google Maps API.
 */
class DynamicGoogleMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.state = {
      routes: {
        DRIVING: { distance: '', duration: '' },
        WALKING: { distance: '', duration: '' },
        BICYCLING: { distance: '', duration: '' },
        TRANSIT: { distance: '', duration: '' },
      },
      origin: '',
      modeInfoVisible: true,
      isAuthenticationModelOpen:false,     
    };
    this.mapContainer = null;
    this.directionsService = null;
    this.directionsRenderer = null;
    this.autocomplete = null;

    this.initializeMap = this.initializeMap.bind(this);
    this.calculateAndDisplayRoute = this.calculateAndDisplayRoute.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);       
  }

  componentDidMount() {
    if (!this.map && this.mapContainer) {
      this.initializeMap();
    }
  }

  initializeMap() {
    const { offsetHeight, offsetWidth } = this.mapContainer;
    const hasDimensions = offsetHeight > 0 && offsetWidth > 0;

    if (hasDimensions) {
      const { center, zoom, address, mapsConfig } = this.props;
      const maps = window.google.maps;
      const controlPosition = window.google.maps.ControlPosition.LEFT_TOP;

      const mapConfig = {
        center,
        zoom,
        mapTypeControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: controlPosition,
        },
      };

      this.map = new maps.Map(this.mapContainer, mapConfig);

      if (mapsConfig.fuzzy.enabled) {
        const GoogleLatLng = window.google.maps.LatLng;
        const origin = { lat: center.lat, lng: center.lng };
        const radius = mapsConfig.fuzzy.offset;
        const path = circlePolyline(origin, radius).map(c => new GoogleLatLng(c[0], c[1]));

        const circleProps = {
          options: {
            fillColor: mapsConfig.fuzzy.circleColor,
            fillOpacity: 0.2,
            strokeColor: mapsConfig.fuzzy.circleColor,
            strokeOpacity: 0.5,
            strokeWeight: 1,
            clickable: false,
          },
          path,
          map: this.map,
        };

        const Polygon = window.google.maps.Polygon;
        new Polygon(circleProps);
      } else {
        new window.google.maps.Marker({
          position: center,
          map: this.map,
          title: address,
        });
      }
    }
    this.directionsService = new window.google.maps.DirectionsService();
    this.directionsRenderer = new window.google.maps.DirectionsRenderer();

    this.directionsRenderer.setMap(this.map);

    const input = document.getElementById('search-input');
    const options = {
            componentRestrictions: { country: 'fr' },
    };

    this.autocomplete = new window.google.maps.places.Autocomplete(input, options);
    this.autocomplete.setFields(['geometry']);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  calculateAndDisplayRoute(origin) {
    const { center } = this.props;
    const travelModes = ['DRIVING', 'WALKING', 'BICYCLING', 'TRANSIT'];
    const destination = center;

    travelModes.forEach(travelMode => {
      const request = {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
      };

      this.directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.directionsRenderer.setDirections(result);
          const route = result.routes[0].legs[0];

          const totalSeconds = route.duration.value;
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.ceil((totalSeconds % 3600) / 60);
          const duration = `${hours} hrs ${minutes} min`;

          this.setState(prevState => ({
            routes: {
              ...prevState.routes,
              [travelMode]: {
                distance: route.distance.text,
                duration: duration,
              },
            },
            modeInfoVisible: false,
          }));
        } else {
          console.error('Directions request failed due to ' + status);
        }
      });
    });
  }
  setAuthenticationModelState(isOpen){
    this.setState({
      isAuthenticationModelOpen: !!isOpen,
    });
  }

  handlePlaceChanged() {
    const {isAuthenticated}= this.props;
    if (!isAuthenticated) {
      this.setAuthenticationModelState(true);    
      return;
    }
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error('No details available for input: ' + place.name);
      return;
    }

    const newCenter = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    const origin = new window.google.maps.LatLng(newCenter.lat, newCenter.lng);

    this.setState({ origin: `${newCenter.lat},${newCenter.lng}` }, () => {
      this.map.setCenter(newCenter);
      this.calculateAndDisplayRoute(origin);
    });
  }

  render() {
    const { containerClassName, mapClassName,intl } = this.props;
    const { routes,modeInfoVisible } = this.state;  

    return (
      <div className={containerClassName}>
        <div className={mapClassName} ref={el => (this.mapContainer = el)} />
        <p>
        <FormattedMessage id="ListingPage.RouteCalculationTiltle" />
        </p>
        <div>
          <input
            id="search-input"
            type="text"
            placeholder= {intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
            style={{ marginBottom: '10px', width: '100%', padding: '10px' }}
          />
        </div>
        <Modal  
        isOpen={this.state?.isAuthenticationModelOpen}
        onClose={() => {
           this.setAuthenticationModelState(false)   
        }       
        }
        onManageDisableScrolling={console.log}
        >        
       <a href= '/login' style={{ display: 'block', textAlign: 'center', cursor: 'pointer',fontSize:'large' }}
       > 
       <FormattedMessage id="ListingPage.PopUpMessage"/>
       </a>  
      </Modal>
        <div className={css.locationTravelmodes}>
        <div className={css.locationItem}>
            <img src={CAR} alt="Driving" width={30} />
            <div className={css.locationValues}>     
            {modeInfoVisible&&(              
            <p>
               <FormattedMessage id="ListingPage.DrivingTitle"/>
            </p>)}    
            <p>{routes['DRIVING'].distance} </p>
            <p>{routes['DRIVING'].duration}</p>           
          </div>                
            
        </div>        
        <div className={css.locationItem}>
            <img src={CYCLE} alt="Cycling" width={30} />
            <div className={css.locationValues}>
            {modeInfoVisible&&(          
            <p>
              <FormattedMessage id="ListingPage.CyclingTitle"/>
            </p>)}
            <p>{routes['BICYCLING'].distance} </p>
            <p>{routes['BICYCLING'].duration}</p>
            </div>
        </div>
          <div className={css.locationItem}>
            <img src={WALK} alt="Walking" width={30}/>
            <div className={css.locationValues}>
            {modeInfoVisible&&(          
            <p>
               <FormattedMessage id="ListingPage.WalkingTitle"/>
            </p>)}
            <p>{routes['WALKING'].distance} </p>
            <p>{routes['WALKING'].duration}</p>
            </div>
          </div>

        <div className={css.locationItem}>
            <img src={PT} alt="Public Transport" width={30}/>
            <div className={css.locationValues}>
            {modeInfoVisible&&(          
            <p>
              <FormattedMessage id="ListingPage.PublicTransportTitle"/>
            </p>)}
            <p>{routes['TRANSIT'].distance} </p>
            <p>{routes['TRANSIT'].duration}</p>
            </div>
          </div>
          
      </div>
      </div>
    );
  }
}


DynamicGoogleMap.defaultProps = {
  address: '',
  center: null,
};

DynamicGoogleMap.propTypes = {
  address: string,
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number.isRequired,
  mapsConfig: object.isRequired,
};

export default injectIntl(DynamicGoogleMap);
