import React, { useEffect, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { Alert, Space } from 'antd';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';
import * as validators from '../../../util/validators';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../../util/validators';
import { PURCHASE_PROCESS_NAME } from '../../../transactions/transaction';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  H3,
  H6,
  FieldLocationAutocompleteInput,
  FieldPhoneNumberInput,
  Button,
  FieldCheckbox,
  FieldRadioButton,
  FieldPhoneInput
} from '../..';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderPreBookingForm.module.css';
import TermsAndConditions from '../../../containers/AuthenticationPage/TermsAndConditions/TermsAndConditions';

// Browsers can't render huge number of select options.
// (stock is shown inside select element)
// Note: input element could allow ordering bigger quantities
const MAX_QUANTITY_FOR_DROPDOWN = 100;

const handleFetchLineItems = ({
  quantity,
  deliveryMethod,
  displayDeliveryMethod,
  listingId,
  isOwnListing,
  fetchLineItemsInProgress,
  onFetchTransactionLineItems,
  couponAmount,
  isPrebooking,
  currentUser,
  listingType,
  address,
}) => {
  const stockReservationQuantity = Number.parseInt(1, 10);
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};
  const isBrowser = typeof window !== 'undefined';
  if (
    isBrowser &&
    !fetchLineItemsInProgress
  ) {
    onFetchTransactionLineItems({
      orderData: { stockReservationQuantity, ...deliveryMethodMaybe, couponAmount: couponAmount, isPrebooking: isPrebooking, currentUser: currentUser, listingType: listingType, address: address },
      listingId,
      isOwnListing,
    });
  }
};

const DeliveryMethodMaybe = props => {
  const {
    displayDeliveryMethod,
    hasMultipleDeliveryMethods,
    deliveryMethod,
    hasStock,
    formId,
    intl,
  } = props;
  const showDeliveryMethodSelector = displayDeliveryMethod && hasMultipleDeliveryMethods;
  const showSingleDeliveryMethod = displayDeliveryMethod && deliveryMethod;
  return !hasStock ? null : showDeliveryMethodSelector ? (
    <FieldSelect
      id={`${formId}.deliveryMethod`}
      className={css.deliveryField}
      name="deliveryMethod"
      label={intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}
      validate={required(intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodRequired' }))}
    >
      <option disabled value="">
        {intl.formatMessage({ id: 'ProductOrderForm.selectDeliveryMethodOption' })}
      </option>
      <option value={'pickup'}>
        {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
      </option>
      <option value={'shipping'}>
        {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
      </option>
    </FieldSelect>
  ) : showSingleDeliveryMethod ? (
    <div className={css.deliveryField}>
      <H3 rootClassName={css.singleDeliveryMethodLabel}>
        {intl.formatMessage({ id: 'ProductOrderForm.deliveryMethodLabel' })}
      </H3>
      <p className={css.singleDeliveryMethodSelected}>
        {deliveryMethod === 'shipping'
          ? intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })
          : intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
      </p>
      <FieldTextInput
        id={`${formId}.deliveryMethod`}
        className={css.deliveryField}
        name="deliveryMethod"
        type="hidden"
      />
    </div>
  ) : (
    <FieldTextInput
      id={`${formId}.deliveryMethod`}
      className={css.deliveryField}
      name="deliveryMethod"
      type="hidden"
    />
  );
};

const identity = v => v;

const renderForm = formRenderProps => {
  const [mounted, setMounted] = useState(false);
  const [successCoupon, setSuccessCoupon] = useState(false);
  const [reductionAmount, setreductionAmount] = useState(0);
  const [invalidCoupon, setinvalidCoupon] = useState(false);
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    allowOrdersOfMultipleItems,
    hasMultipleDeliveryMethods,
    displayDeliveryMethod,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    price,
    payoutDetailsWarning,
    marketplaceName,
    values,
    handleUploadDoc,
    handleCouponValidation,
    config,
    invalid,
    currentUser
  } = formRenderProps;

  // Note: don't add custom logic before useEffect
  useEffect(() => {
    setMounted(true);

    // Side-effect: fetch line-items after mounting if possible
    const { quantity, deliveryMethod } = values;
    if (quantity && !formRenderProps.hasMultipleDeliveryMethods) {
      handleFetchLineItems({
        quantity,
        deliveryMethod,
        displayDeliveryMethod,
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
        isPrebooking: true,
        currentUser,
        listingType: config?.listing?.listingTypes[0]?.listingType,
      });
    }
  }, []);

  // If form values change, update line-items for the order breakdown
  const handleOnListingTypeChange = listingType => {
    if (listingType) {
      handleFetchLineItems({
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
        isPrebooking: true,
        currentUser,
        listingType
      });
    }
  };

  const handleOnChange = formValues => {
    if (formValues.values.address && formValues.values.address.selectedPlace != null) {

      handleFetchLineItems({
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
        isPrebooking: true,
        currentUser,
        listingType: formValues.values.listingType,
        address: formValues.values.address,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (displayDeliveryMethod && !deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const successCouponMessage = intl.formatMessage({ id: 'PartnerPage.successCouponMessage' }, { amount: reductionAmount });

  // Listing is out of stock if currentStock is zero.
  // Undefined/null stock means that stock has never been set.
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasStock = currentStock && currentStock > 0;
  const hasOneItemLeft = currentStock === 1;
  const selectableStock =
    currentStock > MAX_QUANTITY_FOR_DROPDOWN ? MAX_QUANTITY_FOR_DROPDOWN : currentStock;
  const quantities = hasStock ? [...Array(selectableStock).keys()].map(i => i + 1) : [];

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = invalid;

  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const payerAddressRequiredMessa = intl.formatMessage({
    id: 'ProductOrderForm.payerAddressRequiredLabel',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });

  let admissionLetterBlobName = ''; 
  let admissionLetterBlobUrl= '';

  const handleUploadFile = (e) => {

    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    handleUploadDoc(formData).then(res => {
      admissionLetterBlobName = res?.blobName;
      values.admissionLetterBlobName = admissionLetterBlobName;
      values.admissionLetterBlobUrl = res?.blobUrl;
      admissionLetterBlobUrl = res?.blobUrl;
    });    
  }
  const handleValidateCoupon = (res) => {
    var couponCode = values.couponCode
    handleCouponValidation({ couponCode, listingType: values?.listingType }).then(res => {
      if (res && res.length != 0) {
        var couponAmount = res[0].Amount;
        values.couponAmount = res[0].Amount;
        setreductionAmount(couponAmount)
        handleFetchLineItems({
          quantity: 1,
          deliveryMethod: 'pickup',
          listingId,
          isOwnListing,
          fetchLineItemsInProgress,
          onFetchTransactionLineItems,
          couponAmount: couponAmount,
          isPrebooking: true,
          currentUser,
          listingType: values?.listingType || config?.listing?.listingTypes[0]?.listingType,
        });
        setSuccessCoupon(true);
        setinvalidCoupon(false);
      }
      else {
        setinvalidCoupon(true);
        setSuccessCoupon(false);
      }


    })

    var a = values.quantity

  }

  const [selectedValue, setSelectedValue] = useState('Others');

  const handleChangeRadioButton = (event) => {
    setSelectedValue(event.target.value);
  };


  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy
        subscription={{ values: true }}
        onChange={handleOnChange} 
      />

      <h3 className={css.bookingBreakdownTitle}>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>

      <h4><FormattedMessage id="ProductOrderForm.listingType" /></h4>
      <div className={css.couponDiv}>
        <div>
          <FieldSelect
            id="listingType"
            className={css.couponField}
            name="listingType"
            onChange={handleOnListingTypeChange}
            defaultValue={config?.listing?.listingTypes[0]?.listingType}
            validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.listingTypeRequired' }))}
          >
            <option disabled value=''>
              {intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' })}
            </option>
            {(config?.listing?.listingTypes || []).map(item => {

              return (
                <option value={item?.listingType}>
                  {item?.label}
                </option>
              );
            })}
          </FieldSelect>
        </div>
      </div>

      {showBreakdown ? (
        <div className={css.breakdownWrapper}>

          <hr className={css.totalDivider} />
          <EstimatedCustomerBreakdownMaybe
            breakdownData={breakdownData}
            lineItems={lineItems}
            currency={price.currency}
            marketplaceName={marketplaceName}
            processName={PURCHASE_PROCESS_NAME}
            isPrebooking={true}
          />
        </div>
      ) : null}

      {hasNoStockLeft ? null : hasOneItemLeft || !allowOrdersOfMultipleItems ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )}

      <div>
        <div>

{/* <>
        <FieldTextInput
              id={`${formId}.admissionLetterBlobName`}
              className={css.hide}
              name="admissionLetterBlobName"
              type="text" />

            <FieldTextInput
              id={`${formId}.admissionLetterBlobUrl`}
              className={css.hide}
              name="admissionLetterBlobUrl"
              type="text"
              />

          </> */}

          <h4><FormattedMessage id="ProductOrderForm.couponCode" /></h4>
          <div className={css.couponDiv}>
            <div>
              <FieldTextInput
                id={`${formId}.couponCode`}
                className={css.couponField}
                name="couponCode"
                type="text"
                disabled={!formApi?.getFieldState('listingType')?.value}
              />
            </div>
            <Button
              type='button'
              disabled={!formApi?.getFieldState('listingType')?.value}
              className={css.applyButton}
              onClick={() => { handleValidateCoupon() }}
            >
              <FormattedMessage id="ProductOrderForm.apply" />
            </Button>
            <br></br>
          </div>

          {successCoupon ? <span className={css.successCouponmessage}>
            <Alert
              message="Success"
              description={successCouponMessage}
              type="success"
              showIcon
            />
          </span> : null}
          {invalidCoupon ? <span className={css.invalidCouponmessage}><FormattedMessage id="PartnerPage.invalidCouponMessage" /></span> : null}


        </div>
        <div>
          <h4><FormattedMessage id="ProductOrderForm.studentInformation" /></h4>
          <div className={css.formRow}>
            <FieldTextInput
              id={`${formId}.studentFirstName`}
              className={css.firstNameField}
              name="studentFirstName"
              label={intl.formatMessage({ id: 'ProductOrderForm.studentFirstNameLabel' })}
              type="text"
              validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.studentFirstNameRequired' }))}
            />
            <FieldTextInput
              id={`${formId}.studentLastName`}
              className={css.quantityField}
              name="studentLastName"
              label={intl.formatMessage({ id: 'ProductOrderForm.studentLastNameLabel' })}
              type="text"
              validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.studentLastNameRequired' }))}
            />
          </div>

          <div className={css.formRow}>
            <FieldTextInput
              id={`${formId}.studentEmail`}
              className={css.emailField}
              name="studentEmail"
              label={intl.formatMessage({ id: 'ProductOrderForm.studentEmailIdLabel' })}
              type="text"
              validate={validators.composeValidators(
                validators.required(intl.formatMessage({ id: 'ProductOrderForm.studentEmailIdRequired' })),
                validators.emailFormatValid(intl.formatMessage({ id: 'ProductOrderForm.studentEmailIdValid' }))
              )}
            />
            <div className={css.sectionContainer}>
              <FieldPhoneInput
                // className={css.quantityField}
                name="studentWhatsappNumber"
                id={'studentWhatsappNumber'}
                label={intl.formatMessage({ id: 'ProductOrderForm.studentWhatsappNumberLabel' })}
                // placeholder={intl.formatMessage({
                //   id: 'ProfileSettingsForm.whatsappPhoneNumber',
                // })}
                defaultCountry="FR"
                validate={validators.composeValidators(
                  validators.validatePhoneNumber(
                    intl.formatMessage({ id: 'ProfileSettingsForm.phoneNumberValidMessage' })
                  )
                )}
                onChange={e => {
                  formApi.change('studentWhatsappNumber', e);
                }}
              />
            </div>
          </div>

          <div className={css.formRow}>
            <FieldTextInput
              id={`${formId}.studentSchoolName`}
              className={css.quantityField}
              name="studentSchoolName"
              label={intl.formatMessage({ id: 'ProductOrderForm.studentSchoolNameLabel' })}
              type="text"
              validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.studentSchoolNameRequired' }))}
            />
            <div className={css.locationDiv}>
              <FieldLocationAutocompleteInput
                // className={css.quantityField}
                rootClassName={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                // iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                // autoFocus={autoFocus}
                name="studentSchoolCity"
                // label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                label={intl.formatMessage({ id: 'ProductOrderForm.studentSchoolCityLabel' })}
                // placeholder={intl.formatMessage({
                //   id: 'EditListingLocationForm.addressPlaceholder',
                // })}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.studentSchoolCity}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )}
                hideIcon={true}
                isdisplayAllCountries={true}
              />
            </div>
          </div>

          <div className={css.locationDiv}>
              <FieldLocationAutocompleteInput
                // className={css.quantityField}
                rootClassName={css.locationAddress}
                inputClassName={css.locationAutocompleteInput}
                // iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                // autoFocus={autoFocus}
                name="address"
                // label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                label={intl.formatMessage({ id: 'EditListingLocationForm.addressLabel' })}
                // placeholder={intl.formatMessage({
                //   id: 'EditListingLocationForm.addressPlaceholder',
                // })}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.address}
                validate={composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )}
                hideIcon={true}
                isdisplayAllCountries={true}
              />
            </div>

          <div>
            <label> <FormattedMessage id="ProductOrderForm.selectLetter" /></label>
            <input
              type="file"
              id='admissionLetter'
              name='admissionLetter'
              onChange={(e) => { handleUploadFile(e) }}
            />
          </div>       
          <br />
          {/* <h4><FormattedMessage id="ProductOrderForm.payerInformation" /></h4>
          <div>
            <h5><FormattedMessage id="ProductOrderForm.whosPayinglabel" /></h5>

            <FieldRadioButton
              id="Others"
              name="PayingFor"
              label={intl.formatMessage({ id: 'ProductOrderForm.payForSomeOneLabel' })}
              value="Others"
              checked={selectedValue === 'Others'}
              onChange={handleChangeRadioButton}
              isPrebooking={true}
            />
            <FieldRadioButton
              id="Self"
              name="PayingFor"
              label={intl.formatMessage({ id: 'ProductOrderForm.payForMyselfOneLabel' })}
              value="Self"
              checked={selectedValue === 'Self'}
              onChange={handleChangeRadioButton}
              isPrebooking={true}
            />

            <br></br>
          </div>

          {selectedValue == 'Others' ?
            <>
              <div className={css.formRow}>

                <FieldTextInput
                  id={`${formId}.payerName`}
                  className={css.payerNameField}
                  name="payerName"
                  label={intl.formatMessage({ id: 'ProductOrderForm.payerNameLabel' })}
                  type="text"
                  validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.payerNameRequiredLabel' }))}
                />
                <FieldTextInput
                  id={`${formId}.payerSurName`}
                  className={css.quantityField}
                  name="payerSurName"
                  label={intl.formatMessage({ id: 'ProductOrderForm.payerSurNamelabel' })}
                  type="text"
                  validate={validators.required(intl.formatMessage({ id: 'ProductOrderForm.payerNameRequiredLabel' }))}
                />
              </div>

              <div className={css.formRow}>
                <FieldTextInput
                  id={`${formId}.payerEmail`}
                  className={css.emailField}
                  name="payerEmail"
                  label={intl.formatMessage({ id: 'ProductOrderForm.payerEmailLabel' })}
                  type="text"
                  validate={validators.composeValidators(
                    validators.required(intl.formatMessage({ id: 'ProductOrderForm.payerEmailRequiredLabel' })),
                    validators.emailFormatValid(intl.formatMessage({ id: 'ProductOrderForm.studentEmailIdValid' }))
                  )}
                />
                <div className={css.locationDiv}>
                  <FieldLocationAutocompleteInput
                    // className={css.quantityField}
                    rootClassName={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    // iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    // autoFocus={autoFocus}
                    name="payerAddress"
                    // label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
                    label={intl.formatMessage({ id: 'ProductOrderForm.payerAddressLabel' })}
                    // placeholder={intl.formatMessage({
                    //   id: 'EditListingLocationForm.addressPlaceholder',
                    // })}
                    id={`${formId}.payerAddress`}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.payerAddress}
                    validate={composeValidators(
                      autocompleteSearchRequired(payerAddressRequiredMessa),
                      autocompletePlaceSelected(addressNotRecognizedMessage)
                    )}
                    hideIcon={true}
                    isdisplayAllCountries={true}
                  />
                </div>

              </div>
            </>
            : null} */}

          <label>Information</label>
          <span>*<FormattedMessage id="ProductOrderForm.infoText" /></span> <br /> <br />
          <FieldCheckbox
            id="agreementMessage"
            name="agreementMessage"
            rootClassName={css.phoneNumberVerifyFieldCheckbox}
            className={css.phoneNumberVerifyFieldCheckbox}
            label={intl.formatMessage({ id: 'ProductOrderForm.agreementMessageLabel' })}
            validate={validators.requiredFieldCheckbox(intl.formatMessage({ id: 'ProductOrderForm.agreementMessageRequired' }))}
          />
          <div className={css.checkboxDiv}>
            <FieldCheckbox
              id="termsAndCondition"
              name="termsAndCondition"
              rootClassName={css.phoneNumberVerifyFieldCheckbox}
              className={css.phoneNumberVerifyFieldCheckbox}
              validate={validators.requiredFieldCheckbox(intl.formatMessage({ id: 'ProductOrderForm.agreementMessageRequired' }))}
            />
            <span className={css.termsText}>
              <FormattedMessage id="ProductOrderForm.acceptText" />
              <a className={css.anchor} href='https://world-like-home.community/tos'> <FormattedMessage id="ProductOrderForm.wihTerms" /> </a>,
              <a className={css.anchor} href='https://world-like-home.community/privacy'> <FormattedMessage id="ProductOrderForm.privacy" /> </a> <FormattedMessage id="ProductOrderForm.and" />
              <a className={css.anchor} href='https://world-like-home.community/t/charte-de-bonne-conduite-wlh-marketplace/3638'> <FormattedMessage id="ProductOrderForm.codeOfConduct" /> </a>
            </span>
          </div>
        </div>
      </div>




      {/* <DeliveryMethodMaybe
        displayDeliveryMethod={displayDeliveryMethod}
        hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
        deliveryMethod={values?.deliveryMethod}
        hasStock={hasStock}
        formId={formId}
        intl={intl}
      /> */}


      <div className={css.submitButton}>
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          {hasStock ? (
            <FormattedMessage id="ProductOrderForm.ctaButtonBooking" />
          ) : (
            <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
          )}
        </PrimaryButton>
      </div>
      <p className={css.finePrint}>
        {payoutDetailsWarning ? (
          payoutDetailsWarning
        ) : hasStock && isOwnListing ? (
          <FormattedMessage id="ProductOrderForm.ownListing" />
        ) : hasStock ? (
          <FormattedMessage id="ProductOrderForm.finePrint" />
        ) : showContactUser ? (
          <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
        ) : null}
      </p>
    </Form>
  );
}

const ProductOrderPreBookingForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    displayDeliveryMethod,
    allowOrdersOfMultipleItems,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (displayDeliveryMethod && !pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  const hasOneItemLeft = currentStock && currentStock === 1;
  const hasOneItemMode = !allowOrdersOfMultipleItems && currentStock > 0;
  const quantityMaybe = hasOneItemLeft || hasOneItemMode ? { quantity: '1' } : {};
  const deliveryMethodMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : !shippingEnabled && !pickupEnabled
          ? { deliveryMethod: 'none' }
          : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...deliveryMethodMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      displayDeliveryMethod={displayDeliveryMethod}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderPreBookingForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  pickupEnabled: false,
  shippingEnabled: false,
  displayDeliveryMethod: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderPreBookingForm.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  pickupEnabled: bool,
  shippingEnabled: bool,
  displayDeliveryMethod: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderPreBookingForm;
