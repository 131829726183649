import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { useIntl } from '../../util/reactIntl';



const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = () => {
  var { footer = {}, topbar } = useConfiguration();

  const intl = useIntl();

const jsonData = footer;

  const keysToTranslate = new Set([]);
  // Function to replace placeholders with translations
  function replaceContent(obj, keysToTranslate, intl) {
    if (typeof obj === 'string') {
      // Only replace if the string is in the keysToTranslate set
      return intl.messages[obj] ? intl.formatMessage({ id: obj }) : obj;
    } else if (Array.isArray(obj)) {
      return obj.map(item => replaceContent(item, keysToTranslate, intl));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, replaceContent(value, keysToTranslate, intl)])
      );
    }
    return obj;
  }
  // Replace content in the JSON data
  footer = replaceContent(jsonData, keysToTranslate, intl);

 


  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
    linkLogoToExternalSite: topbar?.logoLink,
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
// const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
// export default FooterContainer;

export default FooterComponent;
