import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { addPartners, deletePartners, editPartners, editSetting, getPartners, getSettings } from '../../util/api';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_PARTNERS_REQUEST = 'app/PartnerPage/FETCH_PARTNERS_REQUEST';
export const FETCH_PARTNERS_SUCCESS = 'app/PartnerPage/FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_ERROR = 'app/PartnerPage/FETCH_PARTNERS_ERROR';

export const ADD_PARTNERS_REQUEST = 'app/PartnerPage/ADD_PARTNERS_REQUEST';
export const ADD__PARTNERS_SUCCESS = 'app/PartnerPage/ADD__PARTNERS_SUCCESS';
export const ADD__PARTNERS_ERROR = 'app/PartnerPage/ADD__PARTNERS_ERROR';

export const EDIT_PARTNERS_REQUEST = 'app/PartnerPage/EDIT_PARTNERS_REQUEST';
export const EDIT__PARTNERS_SUCCESS = 'app/PartnerPage/EDIT__PARTNERS_SUCCESS';
export const EDIT__PARTNERS_ERROR = 'app/PartnerPage/EDIT__PARTNERS_ERROR';

export const DELETE_PARTNERS_REQUEST = 'app/PartnerPage/DELETE_PARTNERS_REQUEST';
export const DELETE_PARTNERS_SUCCESS = 'app/PartnerPage/DELETE_PARTNERS_SUCCESS';
export const DELETE_PARTNERS_ERROR = 'app/PartnerPage/DELETE_PARTNERS_ERROR';


export const FETCH_SETTING_REQUEST = 'app/PartnerPage/FETCH_SETTING_REQUEST';
export const FETCH_SETTING_SUCCESS = 'app/PartnerPage/FETCH_SETTING_SUCCESS';
export const FETCH_SETTING_ERROR = 'app/PartnerPage/FETCH_SETTING_ERROR';

export const EDIT_SETTING_REQUEST = 'app/PartnerPage/EDIT_SETTING_REQUEST';
export const EDIT_SETTING_SUCCESS = 'app/PartnerPage/EDIT_SETTING_SUCCESS';
export const EDIT_SETTING_ERROR = 'app/PartnerPage/EDIT_SETTING_ERROR';
// ================ Reducer ================ //



const initialState = {
  fetchInProgress: false,
  fetchPartnerError: null,
  pagination: null,
  transactionRefs: [],
  coupons: [],
  settings: []
};

export default function partnerPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PARTNERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchPartnerError: null };
    case FETCH_PARTNERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        coupons: payload,
      };
    }
    case FETCH_PARTNERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPartnerError: payload };

    case ADD_PARTNERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchPartnerError: null };
    case ADD__PARTNERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        coupons: payload,
      };
    }

    case EDIT_PARTNERS_REQUEST:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: true, fetchPartnerError: payload };
    case EDIT__PARTNERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        coupons: payload,
      };
    }
    case EDIT__PARTNERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPartnerError: payload };

    case DELETE_PARTNERS_REQUEST:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: true, fetchPartnerError: payload };
    case DELETE_PARTNERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        coupons: payload,
      };
    }
    case DELETE_PARTNERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPartnerError: payload };
    default:
      return state;

    case FETCH_SETTING_REQUEST:
      return { ...state, fetchInProgress: true, fetchPartnerError: null };
    case FETCH_SETTING_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        settings: payload,
      };
    }
    case FETCH_SETTING_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPartnerError: payload };

    case EDIT_SETTING_REQUEST:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: true, fetchPartnerError: payload };
    case EDIT_SETTING_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        settings: payload,
      };
    }
    case EDIT_SETTING_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchPartnerError: payload };
  }
}

// ================ Action creators ================ //

const fetchPartnerRequest = () => ({ type: FETCH_PARTNERS_REQUEST });
const fetchPartnersSuccess = response => ({
  type: FETCH_PARTNERS_SUCCESS,
  payload: response,
});
const fetchPartnerError = e => ({
  type: FETCH_PARTNERS_ERROR,
  error: true,
  payload: e,
});

const AddPartnerRequest = () => ({ type: ADD_PARTNERS_REQUEST });
const AddPartnersSuccess = response => ({
  type: ADD__PARTNERS_SUCCESS,
  payload: response,
});
const AddPartnerError = e => ({
  type: ADD__PARTNERS_ERROR,
  error: true,
  payload: e,
});

const editPartnerRequest = () => ({ type: EDIT_PARTNERS_REQUEST });
const editPartnersSuccess = response => ({
  type: EDIT__PARTNERS_SUCCESS,
  payload: response,
});
const editPartnerError = e => ({
  type: EDIT__PARTNERS_ERROR,
  error: true,
  payload: e,
});

const deletePartnerRequest = () => ({ type: DELETE_PARTNERS_REQUEST });
const deletePartnersSuccess = response => ({
  type: DELETE_PARTNERS_SUCCESS,
  payload: response,
});
const deletePartnerError = e => ({
  type: DELETE_PARTNERS_ERROR,
  error: true,
  payload: e,
});


const fetcSettingRequest = () => ({ type: FETCH_SETTING_REQUEST });
const fetchSettingSuccess = response => ({
  type: FETCH_SETTING_SUCCESS,
  payload: response,
});
const fetcSettingError = e => ({
  type: FETCH_SETTING_ERROR,
  error: true,
  payload: e,
});


const editSettingRequest = () => ({ type: EDIT_SETTING_REQUEST });
const editSettingSuccess = response => ({
  type: EDIT_SETTING_SUCCESS,
  payload: response,
});
const editSettingError = e => ({
  type: EDIT_SETTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchPartnerRequest());

  return getPartners({}).then(response => {
    dispatch(fetchPartnersSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(fetchPartnerError(storableError(e)));
      throw e;
    });
};
export const loadSettingData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetcSettingRequest());

  return getSettings({}).then(response => {
    dispatch(fetchSettingSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(fetcSettingError(storableError(e)));
      throw e;
    });
};

export const addPartnerMethod = (params, search) => (dispatch, getState, sdk) => {
  dispatch(AddPartnerRequest());

  return addPartners(params).then(response => {
    dispatch(AddPartnersSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(AddPartnerError(storableError(e)));
      throw e;
    });
}

export const editPartnerMethod = (params, search) => (dispatch, getState, sdk) => {
  dispatch(editPartnerRequest());

  return editPartners(params).then(response => {
    dispatch(editPartnersSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(editPartnerError(storableError(e)));
      throw e;
    });
}

export const deletePartnerMethod = (params, search) => (dispatch, getState, sdk) => {
  dispatch(deletePartnerRequest());

  return deletePartners(params).then(response => {
    dispatch(deletePartnersSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(deletePartnerError(storableError(e)));
      throw e;
    });
}

export const editSettingMethod = (params, search) => (dispatch, getState, sdk) => {
  dispatch(editSettingRequest());

  return editSetting(params).then(response => {
    dispatch(editSettingSuccess(response));
    return response;
  })
    .catch(e => {
      dispatch(editSettingError(storableError(e)));
      throw e;
    });
}
