// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';


export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  //  In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }
  // return `http://localhost:4000`;
  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  if (headers && headers['Content-Type'] === 'application/file') {
    return fetch(url, {
      method: "POST",
      body: body,

    }).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
  }
  else {
    return window.fetch(url, fetchOptions).then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

      if (res.status >= 400) {
        return res.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
  }
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const updateTransitionPrivileged = body => {
  return post('/api/updateTransitionPrivileged', body);
};

export const getAllUsers = body => {
  return post('/api/getAllUsers', body);
};

export const getUser = body => {
  return post('/api/getUser', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const uploadToBlob = body => {

  return post('/api/upload', body, {
    headers: {
      "Content-Type": "application/file"
    }
  });
}

export const sendSMS = body => {
  return post('/api/common/send-sms', body);
};

export const getBlobUrlFromBlobName = body => {
  return post('/api/common/get-blob-url-from-blob-name', body);
};

export const getUserById = (id) => {
  return post('/api/common/get-user-by-id', { id });
};

export const sendContractForESignature = (body) => {
  return post('/api/contract/send-esignature-contract', body);
};

export const updateTransactionMetadata = (body) => {
  return post('/api/transaction/update', body);
};

export const stripeWebhook = body => {
  return post('/api/stripeWebhook', body);
};

export const getPartners = body => {
  return post('/api/getPartners', body);
};

export const addPartners = body => {
  return post('/api/addPartners', body);
};

export const editPartners = body => {
  return post('/api/editPartners', body);
};

export const deletePartners = body => {
  return post('/api/deletePartners', body);
};

export const validateCoupon = body => {
  return post('/api/validateCoupon', body);
};

export const brevoContactCreation = body => {
  return post('/api/brevo/contact-creation', body);
};

export const brevoContactUpdate = body => {
  return post('/api/brevo/contact-update', body);
};

export const brevoDealCreate = body => {
  return post('/api/brevo/deal-create', body);
};

export const brevoDealUpdate = body => {
  return post('/api/brevo/deal-update', body);
};

export const updateUserFirstAndLastConnection = body => {
  return post('/api/user/update-first-and-last-connection', body);
};

export const getUserDetailsByEmail = body => {
  return post('/api/user/get-user-details-by-email', body);
};

export const getSettings = body => {
  return post('/api/getSettings', body);
};

export const editSetting = body => {
  return post('/api/editSetting', body);
};

export const identifyUserWithBrevo = (email) => {
  if(!email) return;
  return post('/api/brevo/identify-user', { email });
};

export const providerNotificationMail = body => {
  return post('/api/providerNotificationMail', body);
};
